import { EventEmitter, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  isLoading = new EventEmitter();
  constructor(private messageService: MessageService) {}

  /**
   * Shows The Success toast
   * @param message Toast Message
   * @param duration Toast Duration. Default to 3000 milliseconds
   */
  showSuccessToast = (message: string, duration?: number) => {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
      life: duration ?? 3000,
    });
  };

  /**
   * Shows The Error toast
   * @param message Toast Message
   * @param duration Toast Duration. Default to 3000 milliseconds
   */

  showErrorToast(message: string, duration?: number) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: duration ?? 3000,
    });
  }

  /**
   * Shows The Warning toast
   * @param message Toast Message
   * @param duration Toast Duration. Default to 3000 milliseconds
   */
  showWarningToast(message: string, duration?: number) {
    this.messageService.add({
      severity: 'warn',
      summary: 'Warning',
      detail: message,
      life: duration ?? 3000,
    });
  }

  /**
   * Shows The Info toast
   * @param message Toast Message
   * @param duration Toast Duration. Default to 3000 milliseconds
   */
  showInfoToast(message: string, duration?: number) {
    this.messageService.add({
      severity: 'info',
      summary: 'Info',
      detail: message,
      life: duration ?? 3000,
    });
  }

  setSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }

  showLoading() {
    this.isLoading.emit(true);
  }

  hideLoading() {
    this.isLoading.emit(false);
  }
}
