export const environment = {
	production: false,

	baseUrl: 'https://api.ganjastan.co.uk',
	imageUrl: 'https://api.ganjastan.co.uk/',
	// baseUrl: 'http://localhost:3000',
	// imageUrl: 'http://localhost:3000/',

	stripePublishableKey: 'pk_test_51LXfU5JQRbMrpuQ9WjGLPXh0Xsiwjok1yGEI52PSWIsYLfLZzgVAtHe8DgjabZJyi2ub5tgJVSh80fjZrll0tv3r00Z5vorrsL',
	paymentSuccessUrl: 'http://ganjastan.co.uk/order-history?ref=1',
	paymentCancelUrl: 'http://ganjastan.co.uk/cart?ref=0',
	maxProductQty: 10,
	supplierPanelUrl: 'https://supplier.ganjastan.co.uk/',
};
