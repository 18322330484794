import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // TODO: integrate user data interface
  userData: any;
  isLogIn: boolean = false;
  onUserLoginStatusChange = new EventEmitter();
  onCartUpdate = new EventEmitter();
  onSystemConfigChange = new EventEmitter();
  systemConfigs: any;

  constructor() {
    this.onSystemConfigChange.subscribe((config) => {
      this.systemConfigs = config;
    });
  }
}
