export class API {
	static GET_BRANDS = '/brand';
	static GET_CATEGORIES = '/categories';
	static GET_CATEGORY_WITH_PRODUCT_COUNT = '/categories/categories-with-count';
	// static GET_PRODUCTS = "/products/all";
	static GET_BRAND = (brandId: string) => `/brand/${brandId}`;
	static GET_BRAND_PRODUCTS = (brandId: string) => `/product/all/${brandId}`;
	static GET_SUPPLIER_PRODUCTS = (supplierId: string) =>
		`/product/supplier/${supplierId}`;
	static FILTER_PRODUCTS = `/product/filter`;

	static PRODUCT_DETAILS = `/product/`;
	static PRODUCT_REVIEWS = `/product/reviews/`;
	static RATE_PRODUCT = `/product/rate-product`;
	static GET_SIMILAR_PRODUCTS = (id: string) => `/product/similar/${id}`;
	static SUBSCRIBE = '/subscribe';
	static PRIVACY_POLICY = '/privacy-policy';
	static TERMS_OF_SERVICE = '/terms-of-service';
	static GET_BLOGS = '/blog/blogs';
	static SUPPLIER_SIGN_UP = '/supplier/sign-up';
	static SUPPLIER_VERIFY_EMAIL = '/supplier/verify-email';
	static SIGN_UP = '/user/sign-up';
	static SIGN_IN = '/user/sign-in';
	static GET_DETAILS = '/user/details';
	static BECOME_TESTER = '/user/become-tester';
	static UPDATE_PROFILE = '/user/update-profile';
	static ADD_ADDRESS = '/user/add-address';
	static UPDATE_SHIPPING_ADDRESS = (addressId: string) =>
		`/user/update-shipping-address/${addressId}`;
	static UPDATE_BILLING_ADDRESS = (addressId: string) =>
		`/user/update-billing-address/${addressId}`;
	static REMOVE_SHIPPING_ADDRESS = (addressId: string) =>
		`/user/remove-shipping-address/${addressId}`;
	static REMOVE_BILLING_ADDRESS = (addressId: string) =>
		`/user/remove-billing-address/${addressId}`;
	static SET_DEFAULT_ADDRESS = '/user/set-default-address';
	static ORDER = '/orders/create';
	static ORDER_HISTORY = '/orders/history';
	static REORDER = '/orders/reorder';
	static RATE_ORDER = (orderId: string) => `/orders/rate-order/${orderId}`;
	//   static ADD_FORUM_QUESTION = '/forum/create';
	//   static ADD_FORUM_ANSWER = '/forum/answer';
	//   static SAVE_FORUM_IMAGES = '/forum/save-forum-images-data';
	//   static GET_PRODUCT_FORUM = (productId: string) => `/forum/product-forum/${productId}`;
	static GET_PRODUCT_FORUM_IMAGES = `/forum/product-images`;

	static VERIFY_ACCOUNT = (email: string, code: string, id: string) =>
		`/user/verify-account?email=${email}&code=${code}&id=${id}&verify=true`;
	static REST_PASSWORD = '/user/reset-password';
	static NEW_PASSWORD = (email: string, code: string, id: string) =>
		`/user/new-password?email=${email}&code=${code}&id=${id}&reset=true`;
	static CHANGE_PASSWORD = '/user/change-password';
	static GET_NOTIFICATIONS = '/user/get-notifications';
	static REMOVE_NOTIFICATIONS = (notificationId: any) =>
		`/user/remove-notifications/${notificationId}`;
	static GET_VOUCHERS = '/user/get-vouchers';
	static GET_FORUM_IMAGE_UPLOAD_URL = '/forum/get-forum-image-upload-url';
	static SUBMIT_CONTACT_FORM = '/submit-contact-form';
	static CALCULATE_TOTAL_SHIPPING = '/orders/calculate-total-and-shipping';
	static GET_SYSTEM_CONFIGS = '/configs';
	static GET_FAQ = '/faq';
	static FAQ_CATEGORIES = '/faq-categories';

	static GET_FORUM_CATEGORIES = '/forum/forum-categories';

	static TOPIC_DETAILS = '/forum/topic-details';
	static TOPIC = '/forum/topic';
	static SUPPLIER_TOPIC = '/forum/supplier-topic';
	static TOPIC_LIKE = `/forum/topic-like`;
	static TOPIC_UNLIKE = `/forum/topic-unlike`;

	static TOPIC_POST = `/forum/post`;
	static POST_UPVOTE = `/forum/post-upvote`;
	static POST_UPVOTE_REMOVE = `/forum/post-upvote-remove`;
	static POST_LIKE = `/forum/post-like`;
	static POST_UNLIKE = `/forum/post-like-remove`;

	static POST_REPLY = `/forum/post-reply`;
	static REPLY_LIKE = `/forum/reply-like`;
	static REPLY_UNLIKE = `/forum/reply-like-remove`;
	static REPLY_UPVOTE = `/forum/reply-upvote`;
	static REPLY_UPVOTE_REMOVE = `/forum/reply-upvote-remove`;

	static POST_IMAGE_UPLOAD = `/forum/post-image`;

	static MODERATION_ACCESS = (categoryId: string) =>
		`/forum/forum-moderator/${categoryId}`;
	static CAN_CREATE = `/forum/can-create`;
	static SUPPLIERS = `/supplier`;
	static FORUM_SUPPLIER_PRODUCT = `/forum/supplier-products`;
	static SUPPLIER_PREVIEW = `/supplier/preview`;
	static LAST_REPLY = '/forum/last-reply';
}
