import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private baseUrl: string = environment.baseUrl;

  constructor(public http: HttpClient) {}

  get(endpoint: string) {
    return this.http.get(this.baseUrl + endpoint);
  }

  post(endpoint: string, body: any) {
    return this.http.post(this.baseUrl + endpoint, body);
  }

  put(endpoint: string, body: any) {
    return this.http.put(this.baseUrl + endpoint, body);
  }

  putPlain(endpoint: string, body: any) {
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'multipart/form-data');
    return this.http.put(endpoint, body, {
      //   headers: headers,
    });
  }

  delete(endpoint: string) {
    // const headers = new HttpHeaders();
    // headers.set('Content-Type', 'application/json');
    return this.http.delete(this.baseUrl + endpoint);
  }

  patch(endpoint: string, body: any) {
    return this.http.patch(this.baseUrl + endpoint, body);
  }
}
